@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ProximaNova', sans-serif;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('/src/assets/fonts/Webfont/ProximaNova-Regular.woff2') format('woff2'),
    url('/src/assets/fonts/TTF/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Body font styles */
body {
  font-family: 'ProximaNova', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}

/* Code font styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* font size for responsiveness */
:root {
  --base-font-size: 16px;
  --scale-factor: 1;
}

@media (max-width: 1000px) {
  :root {
    --scale-factor: 0.85;
  }
}


@media (max-width: 768px) {
  :root {
    --scale-factor: 0.7;
  }
}


.font15 {
  font-size: calc(14px * var(--scale-factor));
  font-weight: 400;
}

.font16-light {
  font-size: calc(16px * var(--scale-factor));
  font-weight: 400;
}

.font16-bold {
  font-size: calc(16px * var(--scale-factor));
  font-weight: 700;
}


.font18-light {
  font-size: calc(18px * var(--scale-factor));
  font-weight: 400;
}

.font18-bold {
  font-size: calc(18px * var(--scale-factor));
  font-weight: 700;
}

.font24-light {
  font-size: calc(22px * var(--scale-factor));
  font-weight: 400;
}

.font24-bold {
  font-size: calc(22px * var(--scale-factor));
  font-weight: 900;
}

.font38-light {
  font-size: calc(36px * var(--scale-factor));
  font-weight: 900;
}

.font38-bold {
  font-size: calc(36px * var(--scale-factor));
  font-weight: 900;
}

.font62-bold {
  font-size: calc(68px * var(--scale-factor)) !important;
  font-weight: 1400;
}

/* Default section padding */
section {
  font-family: 'ProximaNova', sans-serif;
}

@media (max-width: 768px) {
  nav {
    flex-direction: column;
  }

  section {
    padding: 1rem;
  }

  #whychooseus .filter-buttons {
    height: 120px !important;
    width: 110% !important;
    max-width: 220px !important;
    padding-left: 10px !important;
    padding-right: 5px !important;
  }

  #whychooseus .filter-buttons .filter {
    gap: 1px !important;
  }

  #howdoes .howdoes-info {
    margin: 50px auto !important;
    padding: 50px auto !important;
  }


  #awards .swiper {
    max-width: 100% !important;
  }

  #home .badge-container {
    max-width: 140px !important;
  }

  .w-55 {
    width: 100% !important;
  }

  .w-70 {
    width: 100% !important;
  }
}

#howdoes {
  position: relative;
}

#howdoes .howdoes-info {
  width: 90%;
  margin: 20px auto;
  border-radius: 20px;
}

#howdoes .howdoes-info .box-item {
  margin: 0px 20px;
  position: relative;
  text-align: center;
}

#howdoes .howdoes-info .box-item .box-img {
  width: 130px;
  position: relative;
}

#howdoes .howdoes-info .box-item .box-para {
  margin-top: 10px;
}

#howdoes .howdoes-info .box-item .box-para p:nth-child(1) {
  margin-bottom: 10px;
}

#howdoes.boxImg::after {
  right: 0;
  left: inherit;
  width: 20%;
  height: 13%;
}

.arrow {
  height: 2px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: -145px;
}

#howdoes .arrow::after {
  position: absolute;
  content: " ";
  display: inline-block;
  right: -6px;
  top: -6px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid #ddd;
}

#howdoes .row-content {
  display: flex;
  flex-wrap: wrap;

}

.dotted {
  background-image: linear-gradient(90deg, #ddd 5px, transparent 0);
  background-size: 10px 2px;
  background-position: 0 100%;
  animation: colorshade 1s ease-in infinite;
}

@keyframes colorshade {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: 100% 100%;
  }
}


#testimonial.boxImg:after {
  left: inherit;
  right: 0;
}

#testimonial .testimonials .swiper-slide {
  border: 1px solid transparent;
  box-sizing: border-box;
  transform: scale(.8);
  transition: transform .5s ease-in-out;
}


#testimonial .testimonials .swiper-slide.swiper-slide-active {
  box-shadow: 1px 10px 10px transparent;
  transform: scale(1);
}


#testimonial .swiper-button-next,
#testimonial .swiper-button-prev {
  top: 0;
  position: relative;
  left: 50%;
  right: 50%;
  margin: auto;
  display: inline-flex;
}

#testimonial .swiper-button-next:after {
  border-width: 0 3px 3px 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
}

#testimonial .swiper-button-prev:after {
  border-width: 0 3px 3px 0;
  width: 0px;
  height: 0px;
  margin-right: 100px;
  margin-top: 50px;
}

.btn-shadow {
  animation: f 53s linear infinite;
  background: linear-gradient(121.19deg, rgba(133, 239, 49, 0) 25.73%, hsla(0, 0%, 100%, .3) 45.27%, rgba(133, 239, 49, 0) 62.27%), #ff8d1a;
}

.btn-shadow-inverse {
  animation: f 53s linear infinite;
  background: linear-gradient(121.19deg, rgba(247, 94, 83, 0) 25.73%, hsla(17, 100%, 51%, 0.3) 45.27%, rgba(24, 255, 16, 0) 62.27%), #ffffff;
}

@keyframes f {
  0% {
    background-position: -5000px 0;
  }

  100% {
    background-position: 5000px 0;
  }
}


.btn-orange {
  color: #fff;
}

.btn-border:hover {
  color: #fff;
}

#footer .footer_logo {
  max-width: 300px;
}

#footer .address {
  line-height: 2.2rem;
}

#footer ul.address-list li {
  margin: 12px 0px;
}

#footer ul.address-list li .address-icon {
  fill: white;
}

#footer .subscribe-list {
  background: #ddd;
  width: 60%;
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  margin-top: 10px;
}

#footer .subscribe-list .form-control {
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
}

#footer .subscribe-list .envbtn {
  border: none;
}

.social_icon ul li {
  display: inline;
}

.social_icon ul li a {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: transform 1s ease-in-out;
  border-radius: 11px;
  transform: scale(1);
}

.social_icon ul li a:hover {
  transform: scale(1.1);
}

.footer-link {
  margin: 0 15px;
  padding-right: 15px;
  position: relative;
}

.footer-link:not(:last-child):after {
  content: "|";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 20px;
  margin-left: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}


#howdoes .row-content {
  margin-top: 50px;
  border-radius: 20px;
  justify-content: center;
}

#howdoes .row {
  background-color: #fff6f4;
}

#howdoes .box-para h4 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  max-height: 80px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  margin-bottom: 15px;
}

.width-1800 {
  max-width: 1800px;
}

#home {
  padding-bottom: 50px;
  padding-top: 50px;
}

.hero-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hero-img img {
  object-fit: cover;
  max-width: 100%;
  height: auto;
  margin: 0;
  min-width: 350px !important;
  margin-bottom: -50px !important;
}

#home .badge-container {
  position: relative;
  z-index: 20;
  max-width: 180px;
}

@media screen and (max-width: 800px) {
  #footer .subscribe-list {
    width: auto;
  }

  .footer-link {
    margin: 0 7px;
  }

  .with-readon {
    height: 1em;
    display: inline-block;
    vertical-align: bottom
  }

  .children-image {
    display: none;
  }

  .custom-column {
    width: 100% !important;
  }

  .arrow {
    display: none;
  }

  #home {
    padding-top: 50px !important;
  }

  #whychooseus {
    margin-top: 20px !important;
  }

  #home .content-container {
    display: flex !important;
    width: 100% !important;
    margin-left: auto !important;
    max-width: 100% !important;
    align-items: center !important;
  }

  .hero-img {
    position: static;
  }

  .hero-img img {
    max-width: 120% !important;
    margin-right: -35%;
  }
}

#home .content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 525px;
}

#whychooseus {
  margin-top: -28px;
}

#discover .discover-container {
  flex-direction: row;
}

.custom-shape-divider-top-1731248450 {
  position: absolute;
  top: 300px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1731248450 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 135px;
}

.custom-shape-divider-top-1731248450 .shape-fill {
  fill: #ff5a1f;
}

@media screen and (max-width: 1000px) {
  #discover .discover-container {
    flex-direction: column;
  }

  #discover .discover-container .text-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  #discover .discover-container .thumbnail {
    height: 250px !important;
  }

  #discover .discover-container .text-left h2 {
    text-align: center;
  }

  .special-shape-img {
    display: none !important;
  }

  .payment-img {
    display: none;
  }

  .paymentform {
    background: url('./assets/readon_form_bg.jpeg') no-repeat center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .paymentform .formData {
    background-color: #fff;
    box-sizing: border-box;
    margin: auto;
    align-items: center;
    width: auto;
    padding: 40px 30px;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
}


@media screen and (max-width: 1200px) and (min-width: 768px) {
  .custom-column {
    width: 50% !important;
  }

  .special-shape-img {
    display: none !important;
  }

  .arrow {
    display: none;
  }

  #home {
    padding-bottom: 50px !important;
    padding-top: 100px !important;
  }

  .hero-img img {
    object-fit: cover !important;
    min-width: 600px !important;
    margin-bottom: -100px;
  }

}

#whychooseus .filter-buttons {
  height: 120px;
  width: 270px;
  padding-left: 20px;
  padding-right: 20px;
}

#whychooseus .filter-buttons .filter {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
}

#whychooseus .filter-buttons .filter {
  text-align: left;
}

#whychooseus .filter-buttons .filter svg {
  min-width: 30px;
}

.textShadow {
  text-shadow: 1px 1px black;
}

#shadow-host-companion {
  display: none;
}

.custom-column {
  width: 25%;
}

.paymentform .payment-img {
  background: url('./assets/readon_form_bg.jpeg') no-repeat center;
  background-size: cover;
  height: 100vh;
  position: relative;
  border-radius: 15px;
}

.paymentform .payment-img .text-heading {
  font-size: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}

.paymentform .react-tel-input .form-control {
  width: 100%;
}

.paymentform .formData {
  padding: 0px 30px;
}

.paymentform .formData input {
  border: none;
  border-bottom: 1px solid #ddd;
  margin-top: 15px;
  font-size: 1.4rem;
  border-radius: 0;
}

.paymentform .formData input:focus {
  box-shadow: none;
}

.paymentform .formData .proceed-btn {
  width: 30%;
  padding: 0px 10px;
  height: auto;
  background: #f58220 !important;
  border: 1px solid #f58220 !important;
  margin-top: 20px;
}

.paymentform .formData .proceed-btn:hover {
  background-color: #f58220;
  color: #fff;
}

.equal-width {
  width: 80% !important;
}

#readonkey {
  position: relative;
}

#readonkey .section .section-heading {
  text-align: center;
  padding: 0px 10px;
}

#readonkey .wrapper {
  max-width: 1100px;
}

.readonkey-image {
  width: 420px;
  /* margin: auto; // Commented out */
}

#readonkey.boxImg::after {
  width: 22rem;
  height: 9rem;
  top: 37%;
  bottom: inherit;
  transform: translateY(-50%);
}

.btn-cetner {
  display: block;
  text-align: center;
}

.readonkey-info p {
  padding: 10px 0px;
}

/* Media query for max-width: 768px */
@media (max-width: 768px) {
  .readonkey-image {
    max-width: 350px !important;
    width: 100%;
    margin: 10px auto;
  }

  .readonkey-box p {
    font-size: 12px;
    list-style: 1.1rem !important;
  }
}


.ta_HFR_pdf {
  margin-top: 60px;
  background: #e5e7f0;
  display: table;
  width: 100%;
}

#reports .left {
  width: 38%;
  display: table-cell;
  vertical-align: middle;
}

#reports .right {
  display: inline-block;
  vertical-align: middle;
  width: 53%;
  display: table-cell;
  vertical-align: top;
}

/* General styles for the wave container */
.wave-container {
  margin-top: -800px;
  /* Default for large screens */
  position: relative;
  overflow: hidden;
}

/* Adjust for mobile screens (0px to 768px) */
@media only screen and (max-width: 1024px) {
  .wave-container {
    margin-top: 0px;
  }

  #reports .left {
    width: 90%;
    display: block;
    padding: 0;
  }

  #reports .right .img-container {
    top: 350px;
    width: 100%;
  }

  .report-container {
    min-height: 550px;
  }
}

/* Adjust for tablet screens (768px to 1024px) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wave-container {
    margin-top: -700px;
  }
}

/* Adjust for laptop screens (1024px to 1600px) */
@media screen and (min-width: 1024px) {
  .wave-container {
    margin-top: -540px;
  }
}

/* Specific adjustments for very large screens (1600px and up) */
@media screen and (min-width: 1600px) {
  .wave-container {
    margin-top: -260px;
  }
}

/* Styling for the wave image */
.wave-image {
  width: 100%;
  display: block;
  margin-bottom: -2px;
  z-index: -5;
}


.statistics {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: 20px;
  flex-wrap: wrap;
  /* Ensures content wraps when space is limited */
}

.statistics .elements {
  flex: 1;
  padding: 0 20px;
}

.statistics .elements:nth-child(2) {
  border-right: 1px solid #d9dfe7;
  border-left: 1px solid #d9dfe7;
}

@media (max-width: 786px) {
  .statistics {
    flex-direction: column;
    padding: 10px;
  }

  .statistics .elements {
    border: none !important;
    padding: 10px 0;
  }

  #reports .left {
    width: 100% !important;
    display: block;
    padding: 0;
  }

  #reports .right {
    width: 100% !important;
    margin-bottom: -10px;
  }

  #reports .right .img-container {
    top: 30px !important;
    width: 100% !important;
  }

  .report-container {
    position: relative !important;
    flex-direction: column !important;
    min-height: 300px !important;
  }
}

.static-content-wrapper {
  max-width: 1200px;
  padding: 0 15px;
  height: 100%;
  margin: 80px auto;
}

@media screen and (max-width: 1400px) {
  .equal-width {
    width: 90% !important;
  }
}

.w-55 {
  width: 55%;
}

.w-70 {
  width: 70%;
}

@media (max-width: 1024px) {
  .award-image {
    max-width: 150px;
  }
}

@media (max-width: 768px) {
  .award-image {
    max-width: 130px;
  }
}

@media (max-width: 480px) {
  .award-image {
    max-width: 100px;
  }
}